
import Vue, { PropType } from "vue";

import http from "@/http";
import { Building, Coordinate, Snackbar } from "@/interfaces";

import MyMap from "@/components/MyMap.vue";
import axios from "axios";

export interface DataType {
  google: any;
  lat?: number;
  lng?: number;
  valid: boolean;
}

export default Vue.extend({
  components: {
    MyMap
  },
  props: {
    building: {
      type: Object as PropType<Building>,
      required: true
    }
  },
  data(): DataType {
    return {
      google: undefined,
      lat: undefined,
      lng: undefined,
      valid: false
    };
  },
  computed: {
    rules() {
      return {
        lat: [(v: string) => !!v || "緯度は必須です"],
        lng: [(v: string) => !!v || "経度は必須です"]
      };
    }
  },
  methods: {
    async submit() {
      const { id } = this.$route.params;

      const url = `buildings/${id}`;

      const { data } = await http.put<Building>(url, {
        lat: this.lat,
        lng: this.lng
      });

      this.building.position = data.position;
    },
    async click() {
      const url = "https://navi.urbanhome.co.jp/api/coordinate";

      try {
        const { data } = await axios.get<Coordinate>(url, {
          params: {
            building_id: this.building.id
          }
        });

        const [lng, lat] = data.geometry.coordinates;

        this.lng = lng;
        this.lat = lat;
      } catch (error) {
        const snackbar: Snackbar = {
          show: true,
          content: "データを取得できませんでした",
          color: "error"
        };

        this.$store.commit("setSnackbar", snackbar);
      }
    }
  }
});
