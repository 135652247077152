
import Vue, { PropType } from "vue";
export default Vue.extend({
  props: {
    lat: {
      type: Number as PropType<number>,
      required: true
    },
    lng: {
      type: Number as PropType<number>,
      required: true
    }
  },
  computed: {
    src(): string {
      return `https://www.google.com/maps?output=embed&q=${this.lat},${this.lng}`;
    }
  }
});
